import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Boxbuild Assembly",
  "description": "A turnkey or box-build assembly is the process of putting together electrical and mechanical parts into a validated, working product.",
  "author": "Huang Zhongbin",
  "categories": ["turnkey"],
  "date": null,
  "featured": false,
  "tags": ["boxbuild", "turnkey"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-is-turnkey--boxbuild-assembly"
    }}>{`What is Turnkey / Boxbuild Assembly?`}</h2>
    <p>{`A turnkey or box-build assembly is the process of putting together electrical and mechanical parts into a validated, working product.`}</p>
    <h2 {...{
      "id": "parts-of-a-box-build-assembly"
    }}>{`Parts of a box-build assembly`}</h2>
    <h3 {...{
      "id": "1-pcba-pcb-assembly"
    }}>{`1. PCBA (PCB Assembly)`}</h3>
    <p>{`The process of a PCB Assembly or PCBA involves the placement of components into a bare circuit board.`}</p>
    <p>{`As an assembly should be a detailed and crucial process, NexPCB has our priorities set up for the whole PCBA process:`}</p>
    <ol>
      <li parentName="ol">{`Bare PCBs are usually sampled for testing first, to make sure that everything is up to the standard even before the assembly process.`}</li>
      <li parentName="ol">{`Components, based on their types, are also checked through the IQC process. However, one of the most crucial parts of handling PCB components is the `}<strong parentName="li">{`lead times.`}</strong></li>
    </ol>
    <p>{`The lead times of electrical components continue to vary by time, based on the market conditions and demand. Some have a one-week lead time, some have a few months, and some might take even up to a year of lead time. The key of optimizing the procurement process is to establish an agile supply chain management.`}</p>
    <p>{`Other than these two points, here is the complete list of factors that you should pay attention to when doing your PCBA:`}</p>
    <ul>
      <li parentName="ul">{`Procurement of electronic components (especially crucial chips, and their long lead-time supporting components)`}</li>
      <li parentName="ul">{`PCB fabrication and processing (proofing, sealing, mass production)`}</li>
      <li parentName="ul">{`PCBA (proofing, sealing, mass production)`}</li>
      <li parentName="ul">{`Management of material delivery`}</li>
      <li parentName="ul">{`Material Inventory Management`}</li>
      <li parentName="ul">{`Tooling for validation tests`}</li>
      <li parentName="ul">{`Product debugging guide`}</li>
    </ul>
    <h3 {...{
      "id": "2-product-assembly"
    }}>{`2. Product Assembly`}</h3>
    <p>{`Before assembling the whole product (i.e. product assembly or final assembly), here are a few points that you should prepare:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Parts’ standard of quality`}</strong>{`: For each part, we need to establish quality standards (dimensional tolerance standards, appearance standards). In this way, it can be ensured that the parts will not interfere with each other and cannot be assembled together when they are assembled.`}</li>
      <li parentName="ul"><strong parentName="li">{`BOM`}</strong>{`: For different products, the type and quantity of the parts that make up it will also have many differences, so we will have a corresponding Bom for each product to manage the parts and usage of the product. ensure its uniqueness and accuracy`}</li>
      <li parentName="ul"><strong parentName="li">{`Product Assembly Instructions`}</strong>{`: Each product requires an assembly instruction`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooling requirements:`}</strong>{` Do you need assembly tooling and auxiliary tools?`}</li>
      <li parentName="ul"><strong parentName="li">{`PCB Assembly Instructions`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Wiring:`}</strong>{` Do you need any connecting wires to be glued`}</li>
      <li parentName="ul"><strong parentName="li">{`Subassembly details:`}</strong>{` any lower level assembly details of the product’s parts`}</li>
      <li parentName="ul"><strong parentName="li">{`Casing assembly`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Machine function test`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Labeling`}</strong>{` (barcode, QR code, and other information)`}</li>
      <li parentName="ul"><strong parentName="li">{`Product packaging`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Logistics`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      